import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import outlook_local_1 from '../../../../../../../common/src/assets/image/outlook_local_1.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        PrintableCal can create printable calendars containing events from Outlook (version 2007 or later) in Excel, Word, and PowerPoint. Rather than being limited to the few calendar layouts provided in Outlook, you can choose from
        over <Link to="/Documentation/Create-Calendar/Choose-a-Template">90 different calendar templates and layouts</Link>, which can also be <Link to="/Documentation/How-To/Customize-a-Template">customized</Link> with your own logo, contact
        information, or just about anything else you'd like to include. <br/><br/>Note, if you use the web-based Outlook.com, see the{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Outlookcom">
          Outlook.com instructions
        </Link>{' '}
        instead. If your calendars are synced with Exchange Server, they might
        not be available in your local Outlook data file. If that's the case,
        see the{' '}
        <Link to="/Documentation/Create-Calendar/Calendar-Data-Sources/Exchange-Server">
          Exchange Server
        </Link>{' '}
        instructions.
        <br />
        <br />
        Here's how to connect PrintableCal to your calendars in Outlook:
      </p>
      <ol className="numbered">
        <li>
          Click the <strong>Add Calendar</strong> button on the Calendar Data
          Sources step and select the{' '}
          <strong>Local -&gt; Outlook (Local) </strong>option. The{' '}
          <strong>Add Calendar Source - Outlook (Local) </strong>window will
          appear.
          <br />
          <Image alt="" src={outlook_local_1} style={{ width: 590 }} />
        </li>
        <li>
          Local calendars will be listed, each which a checkbox to the left of
          its name.
        </li>
        <li>
          Use the checkboxes to select the calendars you'd like to make
          available to PrintableCal.
        </li>
        <li>
          ​Click the <strong>OK </strong>button to add the selected Outlook
          calendars to PrintableCal's list of available calendar sources.
        </li>
      </ol>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Calendar-Data-Sources/Outlook-Local"
      commentsId="commentsplus_post_147_489"
      title="Print Outlook Calendar"
      description="PrintableCal can create printable calendars containing events defined in Microsoft Outlook."
      keywords="print Outlook, customize Outlook printing, calendar template, word-wrap Outlook events, import Outlook events, Excel calendar, Word calendar, printable calendar"
      content={content}
    />
  );
};

export default Documentation;
